<template>
    <div class="container">
        <div class="container__row">
            <div class="container__item">
                <vessel-info />
            </div>
        </div>
        <div class="container__row">
            <div class="container__item container__item_half">
                <portcall-states />
            </div>
            <div class="container__item container__item_half">
                <portcall-locations />
            </div>
        </div>
        <div class="container__row">
            <div class="container__item">
                <portcall-timeline />
            </div>
        </div>
        <div class="container__row">
            <div class="container__item">
                <portcall-demurrage-timeline />
            </div>
        </div>
        <div class="container__row">
            <div class="container__item">
                <statement-log />
            </div>
        </div>
        <div class="container__row">
            <div class="container__item">
                <chat />
            </div>
        </div>
        <div class="container__row">
            <div class="container__item">
                <vessel-traffic />
            </div>
        </div>
    </div>
</template>

<script>
    import VesselTraffic from '@/components/vessel-components/VesselTraffic';
    import StatementLog from '@/components/statement-components/StatementLog';
    import PortcallTimeline from '@/components/port-call-components/PortcallTimeline';
    import PortcallLocations from '@/components/port-call-components/PortcallLocations';
    import PortcallStates from '@/components/port-call-components/PortcallStates';
    import VesselInfo from '@/components/vessel-components/VesselInfo';
    import Chat from '@/components/Chat';
    import PortcallDemurrageTimeline from '@/components/port-call-components/PortcallDemurrageTimeline';
    import { mapGetters } from 'vuex';

    export default {
        name: 'PortcallPreview',
        components: {
            VesselTraffic,
            Chat,
            StatementLog,
            PortcallTimeline,
            PortcallLocations,
            PortcallStates,
            PortcallDemurrageTimeline,
            VesselInfo,
        },
        watch: {
            '$route.params.id': {
                handler(val) {
                    if (this.selectedPortCallId !== val && val) {
                        this.$store.dispatch('getPortCall', val);
                    } else if (!val) {
                        this.$store.commit('setSidebarValue', true);
                    }
                },
                immediate: true,
            },
        },
        mounted() {
            window.scrollTo(0, 0);
        },
        computed: {
            ...mapGetters(['selectedPortCallId']),
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/sass/default/layout.scss';
</style>
